#fl-dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
}


@media screen and (max-width: 768px){
    #fl-dropdown-button {
        font-size: 1.25rem !important;
    }
}