#form-dialog-title {
    font-size: 1.25rem;
    color: #E8339E !important;
    font-weight: bold;
    min-width: 30vw;
}

#fl-dialog-container{
    padding: 3em !important;
}

.fl-helper-text{
    text-align: end !important;
}

@media(max-width: 1024px) {
    #form-dialog-title {
        font-size: 1rem;
    }
}
