#form-dialog-title {
    font-size: 1.25rem;
    color: #E8339E !important;
    font-weight: bold;
    min-width: 30vw;
}

#fl-dialog-container{
    padding: 3em !important;
}

.MuiDialog-paper{
    max-width: 100% !important;

}

.dialogContentContainer {
    min-width: 44vw !important;
}

.fl-helper-text{
    text-align: end !important;
}

.separator {
    width: 1px;
    background-color: #c3c3c3f8;
}

.facebookLoginButton {
    display: flex;
    background-color: #1877F2;
    align-items: center;
    cursor: pointer;
}

.facebookLoginButton:hover {
    opacity: 0.9;
}

/* Resolución: Medium Desktop */
@media (max-width: 1200px) {
    .dialogContentContainer {
        min-width: 52vw !important;
    }
}

@media(max-width: 1024px) {
    #form-dialog-title {
        font-size: 1rem;
    }

    .dialogContentContainer {
        min-width: 55vw !important;
    }
}

@media (max-width: 980px) {
    #fl-dialog-container{
        padding: 0 !important;
    }
    .dialogContentContainer {
        min-width: 85vw !important;
    }
}
