/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@800&display=swap'); */
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@300;800&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;500;600;700;800&display=swap');

:root {
  --extralarge-desktop: 2400px;
  --large-desktop: 1920px;
  --special-screen: 1680px;
  --bigmac-desktop: 1540px;
  --mac-desktop: 1440px;
  --desktop: 1366px;
  --medium-desktop: 1200px;
  --small-desktop: 1024px;
  --laptop: 980px;
  --tablet: 800px;
  --small-tablet: 720px;
  --large-mobile: 620px;
  --mobile: 500px;
  --small-mobile: 420px;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 2800px;
  margin: auto;
  color: "#3D3D3D";
  overflow: overlay;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
}

.p-treeselect-panel .p-treeselect-header {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #BCBCBC;
  border-radius: 20px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  font-family: 'Open Sans', sans-serif;
}

input {
  font-family: 'Open Sans', sans-serif;
}

.fl-font-family {
  font-family: 'Open Sans', sans-serif;
}

.fl-text-primary {
  color: #E8339E !important;

}

.fl-button-secondary {
  border: 2px solid #E8339E;
  background-color: #fff;
  color: #E8339E;
  border-radius: .5em;
  padding: .5em 1.25em;
  cursor: pointer;
}

.fl-button-secondary:hover {
  background-color: #e8339d10;
}

.fl-button-primary {
  border: 2px solid #E8339E;
  background-color: #E8339E;
  color: #fff;
  border-radius: .5em;
  padding: .5em 1.25em;
  cursor: pointer;
}

.fl-button-primary:hover {
  background-color: rgb(234, 24, 146)
}

.fl-border {
  border: 1px solid #E8339E;
  border-radius: 1em;
}

.MuiTypography-root {
  font-family: 'Open Sans', sans-serif !important;
}