.socialNetworksContainer {

    .socialNetwork {
        display: -webkit-box; /* Prefijo para Safari y otros Webkit browsers */
        display: -ms-flexbox; /* Prefijo para IE10 */
        display: flex;
        align-items: center;
        height: 25%;
        width: 100% !important;
        justify-content: flex-end;
    }

    .socialNetwork.disconnected {
        align-items: start !important;
        margin-right: 3em;
    }

    .socialNetworkConnected {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;

        hr {
            border: 2.5px solid #fff;
        }

        .socialNetworkIcon {
            background-color: #000;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -.5em;
            cursor: pointer;

            svg {
                width: 1.75em;
                height: 1.75em;
            }
        }

        .followersCount {
            color: #fff;
            font-weight: bold;
            font-size: 1.375em;
            margin: 0 0 0 .625em !important;
        }

        .revokeButton {
            cursor: pointer;
            width: 1em;
            height: 1em;
            margin-left: .625em;
        }

        .externalLink {
            margin-left: .625em;
        }
    }

    .socialNetworkDisconnected {
        border-bottom: 1.5px solid #fff;
        font-size: 1.25em;
        gap: .25em;
        margin-top: .5em !important;

        svg {
            width: 1.25em;
            height: 1.25em;
            margin-bottom: -.125em;
        }
    }

    .revokeButton {
        cursor: pointer;
    }
}

.instagram .socialNetworkConnected:hover {
    hr {
        border-color: #E8339E;
    }
    p {
        color: #E8339E;
    }
    svg {
        path {
            fill: #E8339E;
        }
    }
}

.facebook .socialNetworkConnected:hover {
    hr {
        border-color: #3D6AD6;
    }
    p {
        color: #3D6AD6;
    }
    svg {
        path {
            fill: #3D6AD6;
        }
    }
}

.tikTok .socialNetworkConnected:hover {
    hr {
        border-color: #F00044;
    }
    p {
        color: #F00044;
    }
    svg {
        path.principal {
            stroke-width: 0px;
        }

        path.magenta {
            fill: #08FFF9;
            stroke-width: 0px;
        }

        path.rosa {
            fill: #F00044;
        }
    }
}

.youTube .socialNetworkConnected:hover {
    hr {
        border-color: red;
    }
    p {
        color: red;
    }
    svg {
        path {
            fill: red;
        }
    }
}

/* Resolución: Large Desktop */
@media (min-width: 1920px) {
    /* Tus estilos aquí */
}

/* Resolución: Special Screen */
@media (min-width: 1680px) {
    .socialNetworksContainer {
        .socialNetworkConnected {
            .socialNetworkIcon {
                svg {
                    width: 3.125em !important;
                    height: 3.125em !important;
                }
            }
        }
    }
}

/* Resolución: Big Mac Desktop */
@media (min-width: 1540px) {
    .socialNetworksContainer {
        .socialNetworkConnected {
            .socialNetworkIcon {
                svg {
                    width: 2.75em;
                    height: 2.75em;
                }
            }
            .followersCount {
                font-size: 1.625rem;
            }
            .revokeButton {
                width: 1.25em;
                height: 1.25em;
            }
        }
    }
}

/* Resolución: Mac Desktop */
@media (min-width: 1440px) {
    /* Tus estilos aquí */
    .socialNetworksContainer {
        .socialNetworkConnected {
            .socialNetworkIcon {
                svg {
                    width: 2.5em;
                    height: 2.5em;
                }
            }
        }
    }
}

/* Resolución: Desktop */
@media (min-width: 1366px) {
    /* Tus estilos aquí */
    .socialNetworksContainer {
        .socialNetworkConnected {
            .socialNetworkIcon {
                svg {
                    width: 2.25em;
                    height: 2.25em;
                }
            }
        }
    }
}

/* Resolución: Desktop */
@media (max-width: 1366px) {
    .socialNetworksContainer {
        .socialNetworkConnected {
            hr {
                border: 1.5px solid #fff;
            }
            .followersCount {
                font-size: 1.125rem;
            }
        }
    }
}

/* Resolución: Medium Desktop */
@media (max-width: 1200px) {
    .socialNetworksContainer {
        .socialNetworkConnected {
            hr {
                border: 1.5px solid #fff;
            }
            .followersCount {
                font-size: 1.125rem;
            }
        }

        .socialNetwork.disconnected {
            margin-right: 0;
        }
    }
}

/* Resolución: Small Desktop */
@media (max-width: 1024px) {
    /* Tus estilos aquí */
}

/* Resolución: Laptop */
@media (max-width: 980px) {
    .socialNetworksContainer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        width: fit-content !important;

        .socialNetworkConnected {
            display: none !important;
        }

        .socialNetworkConnectedMobile {
            cursor: pointer;
            margin-right: .375em;
            margin-left: .375em;
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            align-items: center;
            justify-content: center;

            .followersCountMobile {
                display: -webkit-box !important;
                display: -ms-flexbox !important;
                display: flex !important;
                font-size: 1rem;
                color: #fff;
                margin-left: -.25em;
                padding: 0 .625em;
                background-color: rgba(61, 61, 61, 0.4);
                border-radius: 0 20px 20px 0;
            }
        }

        .socialNetworkDisconnected {
            width: min-content !important;
            justify-content: center !important;
            align-items: center !important;
            height: 100% !important;
            border: none;
            margin-right: .375em;
            margin-left: .375em;
            margin-top: 0 !important;
        }
    }
}

/* Resolución: Tablet */
@media (max-width: 800px) {
    /* Tus estilos aquí */
}

/* Resolución: Small Tablet */
@media (max-width: 720px) {
    /* Tus estilos aquí */
}

/* Resolución: Large Mobile */
@media (max-width: 620px) {
    /* Tus estilos aquí */
}