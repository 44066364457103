.tikTokButtonDesktop {
    cursor: pointer;
}

.tikTokButtonMobile {
    background-color: #E4E6EB;
    cursor: pointer;
    width: 38.5px;
    height: 38.5px;
}

@media screen and (max-width: 980px) {
    .tikTokButtonMobile {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    
    .tikTokButtonDesktop {
        display: none !important;
    }
}